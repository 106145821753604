import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { CreateComponentQuery, UpdateComponentQuery } from './query';
import { PartModelsListQuery } from '../../../api/common-queries/parts';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/Modal';
import { MultiSelect } from '../../../components/MultiSelect';
import { TextArea } from '../../../components/TextArea';
import { ToastContent } from '../../../components/ToastContent';
import { ProductModelsQuery } from '../../../api/common-queries/pms';
import { ReactComponent as ColumnsIcon } from '../../../assets/icons/columns.svg';
import { ReactComponent as PartIcon } from '../../../assets/icons/part.svg';
import styles from './styles.module.css';

export const CreateComponentModal = ({ editModalData, onClose }: any) => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>(editModalData.name || '');
  const [description, setDescription] = useState<string>(editModalData.description || '');
  const [linkedProductModels, setLinkedProductModels] = useState<string[]>(editModalData.linkedProductModelsIds || []);
  const [linkedPartModels, setLinkedPartModels] = useState<string[]>(editModalData.linkedPartModelsIds || []);

  const [createComponent] = useMutation(CreateComponentQuery);
  const [updateComponent] = useMutation(UpdateComponentQuery);
  const { data: productModelsData } = useQuery(ProductModelsQuery);
  const productModels = productModelsData?.products?.data || [];
  const { data: partModelsData } = useQuery(PartModelsListQuery);
  const partModels = partModelsData?.partModels?.data || [];

  const isEdit = editModalData?.modifyType === 'edit';
  const actionName = isEdit ? 'Update' : 'Create';
  const actionDoneLabel = isEdit ? 'updated' : 'created';

  const onSubmit = async () => {
    const method = isEdit ? updateComponent : createComponent;
    const componentRes = await method({
      variables: {
        id: isEdit ? editModalData.id : undefined,
        name,
        description,
        products: linkedProductModels,
        partModels: linkedPartModels,
      },
    });
    const newComponentId = isEdit ? componentRes?.data?.updateComponent?.data?.id : componentRes?.data?.createComponent?.data?.id;
    toast(<ToastContent title={`Component ${actionDoneLabel}!`} message={`Your new component is successfully ${actionDoneLabel}`} />);
    navigate(`/component/${newComponentId}`);
    onClose();
  };

  if (!!editModalData) {
    return (
      <Modal onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.title}>
            <h1>{actionName} Component</h1>
          </div>
          <div className={styles.content}>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ColumnsIcon />
                Product Models
              </div>
              <div className={styles.fieldsColumn}>
                <MultiSelect
                  value={linkedProductModels}
                  placeholder="Product Models"
                  options={productModels.map((pm: any) => ({
                    key: pm.id,
                    label: pm.attributes?.name || `[ID ${pm.id}]`
                  }))}
                  onChange={(newValue) => {
                    setLinkedProductModels(newValue);
                  }}
                />
              </div>

              <div className={styles.subCategory}>
                <PartIcon />
                Parts
              </div>
              <div className={styles.fieldsColumn}>
                <MultiSelect
                  value={linkedPartModels}
                  placeholder="Select Parts"
                  options={partModels.map((partModel: any) => ({
                    key: partModel.id,
                    label: partModel.attributes?.name || `[ID ${partModel.id}]`
                  }))}
                  onChange={(newValue) => {
                    setLinkedPartModels(newValue);
                  }}
                />
              </div>

              <div className={styles.subCategory}>
                <ColumnsIcon />
                Component Information
              </div>
              <div className={styles.fieldsColumn}>
                <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"
                          className={styles.descriptionField}/>
              </div>
            </div>
            <div className={styles.bottomActions}>
              <button className={styles.cancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button
                className={styles.createBtn} onClick={onSubmit}
                disabled={!name || !linkedProductModels?.length || !linkedPartModels?.length}
              >
                {actionName}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
