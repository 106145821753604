import { gql } from '@apollo/client';

export const ProductModelDetailsQuery = gql`
    query GetProduct($id: ID) {
        product(id: $id) {
            data {
                id
                attributes {
                    productID
                    name
                    description
                    assets {
                        data {
                            id
                            attributes {
                                assetId
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    build {
                        data {
                            id
                            attributes {
                                name
                                image {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                number
                            }
                        }
                    }
                    part_models {
                        data {
                            id
                            attributes {
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    manufacturer {
                        data {
                            attributes {
                                business_info {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    product_category {
                        data {
                            attributes {
                                value
                                product_classification {
                                    data {
                                        attributes {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                    }
                    resources {
                        data {
                            id
                            attributes {
                                name
                                type
                                url
                            }
                        }
                    }
                    specification {
                        data {
                            attributes {
                                height
                                depth
                                width
                                weight
                            }
                        }
                    }
                }
            }
        }
    }
`;
