import React from 'react';
import styles from './styles.module.css';

type Tab = {
  key: string;
  name: string;
};

export const Tabs = ({ currentTab, onChange, tabs }: { currentTab: string; onChange: (tab: string) => void; tabs: Tab[] }) => {
  const renderTab = (tab: Tab) => (
    <div key={tab.key} onClick={() => onChange(tab.key)} className={tab.key === currentTab ? styles.activeTab : styles.tab}>
      {tab.name}
    </div>
  );

  return (
    <div className={styles.tabs}>
      {tabs.map(renderTab)}
    </div>
  );
};
