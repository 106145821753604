import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonCircular } from "../ButtonCircular";
import { ReactComponent as SearchIcon } from "../../assets/icons/header/search.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/header/notification.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/menus/profile.svg";
import { apiClient } from "../../api/apiClient";
import { useAuthContext } from "../../contexts/AuthContext";
import styles from './styles.module.css';

export const Header = () => {
  const { signOut } = useAuthContext();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<{ firstName: string }>();
  const [isActionsShown, setIsActionsShown] = useState(false);

  useEffect(() => {
    const init = async () => {
      const res = await apiClient.get('/users-permissions/auth/verifyUser');
      setUserData(res?.data);
    };
    init();
  }, []);

  const onLogout = () => {
    setIsActionsShown(false);
    signOut();
    navigate('/sign-in/email');
  };

  return (
    <header className={styles.container}>
      <ButtonCircular><SearchIcon /></ButtonCircular>
      <div className={styles.rightActions}>
        <ButtonCircular><span className={styles.lang}>EN</span></ButtonCircular>
        <ButtonCircular><NotificationIcon /></ButtonCircular>
        <div className={styles.userDropdownContainer}>
          <div className={styles.userDropdownCta} onClick={() => setIsActionsShown(!isActionsShown)}>
            <ButtonCircular isDisabled isDark width={45} height={45}>
              <ProfileIcon/>
            </ButtonCircular>
            <div className={styles.userInfo}>
              <p className={styles.userName}>{userData?.firstName || ' '}</p>
              <p className={styles.userType}>Social Profile</p>
            </div>
          </div>
          {isActionsShown && (
            <div className={styles.userActions}>
              <button className={styles.userAction} onClick={onLogout}>Logout</button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
