import { useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import styles from './styles.module.css';
import { useHandleOutsideMouseDown } from "../../hooks/useHandleOutsideMouseDown";

interface IMultiSelectProps {
  label?: string;
  value: string[];
  options: { key: string; label: string }[];
  onChange: (optionsKeys: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const MultiSelect = ({ label, value, options = [], onChange, placeholder = 'Select...', disabled = false }: IMultiSelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);
  const selectedLabel = value.map(entry => options.find(option => option.key === entry)?.label).join(', ');
  const showSmallLabel = !label && placeholder;
  useHandleOutsideMouseDown(selectRef, () => setShowOptions(false));

  return (
    <div className={styles.selectWrapper}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={`${styles.select} ${disabled ? styles.selectDisabled : ''}`} onClick={() => {
        !disabled && setShowOptions(!showOptions);
      }} ref={selectRef}>
        <p className={`${styles.value} ${!selectedLabel ? styles.placeholder : ''}`}>{selectedLabel || placeholder}</p>
        <ArrowDownIcon className={showOptions ? styles.iconInverted : styles.icon} />
        {showOptions && (
          <div className={styles.optionsList} onClick={e => e.stopPropagation()}>
            {options.map((option) => (
              <div key={option.key} className={`${styles.option} ${value.includes(option.key) ? styles.optionSelected : ''}`} onClick={() => {
                if (value.includes(option.key)) {
                  onChange(value.filter(entry => entry !== option.key));
                } else {
                  onChange([...value, option.key]);
                }
              }}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {showSmallLabel && <p className={`${styles.smallLabel} ${selectedLabel ? styles.smallLabelActive : ''}`}>
        {showSmallLabel}
      </p>}
    </div>
  );
};
