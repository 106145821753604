import { useMemo, useState } from 'react';
import Table from "../../components/Table/Table";
import styles from "../product-models/styles.module.css";
import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';

export const AssetNestedItemsTable = ({ assetData, currentTab }: any) => {
  const [search, setSearch] = useState('');
  const rows = useMemo(
    () => {
      if (currentTab === 'resources') {
        return (assetData?.resources || []).map((resource: any) => ({
          id: resource?.id,
          name: resource?.attributes.name,
          category: assetData?.category,
          url: resource?.attributes.url,
        })).filter((resourceModel: any) => Object.values(resourceModel).some((resourceModelParam: any) => resourceModelParam && typeof resourceModelParam === 'string' && resourceModelParam.includes(search)));
      }
      if (currentTab === 'components') {
        return (assetData?.components || []).map((componentAsset: any) => ({
          id: componentAsset?.id,
          name: componentAsset?.attributes.component?.data?.attributes?.name,
          status: componentAsset?.attributes.component?.data?.attributes?.status,
        })).filter((componentModel: any) => Object.values(componentModel).some((componentModelParam: any) => componentModelParam && typeof componentModelParam === 'string' && componentModelParam.includes(search)));
      }
      if (currentTab === 'parts') {
        return (assetData?.parts || []).map((partModel: any) => ({
          id: partModel?.id,
          name: partModel?.attributes.name,
          image: partModel?.attributes.images?.data?.[0]?.attributes.url,
          partId: partModel?.id,
          category: assetData?.category,
          status: 'Active',
        })).filter((partModel: any) => Object.values(partModel).some((partModelParam: any) => partModelParam && typeof partModelParam === 'string' && partModelParam.includes(search)));
      }
    },
    [assetData, currentTab, search]
  );

  const columns = useMemo(
    () => {
      if (currentTab === 'resources') {
        return [
          {
            field: 'name',
            headerName: 'Build Name',
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'category',
            headerName: 'Category',
            minWidth: 400,
            flex: 1,
            filterName: 'category',
          },
        ];
      }
      if (currentTab === 'components') {
        return [
          {
            field: 'name',
            headerName: 'Component Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                <AssetIcon className="product-image" />
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'id',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'id',
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 400,
            flex: 1,
            filterName: 'status',
          },
        ];
      }
      if (currentTab === 'parts') {
        return [
          {
            field: 'name',
            headerName: 'Part Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'partId',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'partId',
          },
          {
            field: 'category',
            headerName: 'Category',
            minWidth: 300,
            flex: 1,
            filterName: 'category',
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 300,
            flex: 1,
            filterName: 'status',
          },
        ];
      }
    },
    [currentTab]
  );

  return (
    <Table
      isLoading={false}
      onClick={(e: any) => e.data?.url && window.open(e.data?.url)}
      columns={columns}
      filters={[]}
      search={search}
      header={undefined}
      hideFilters
      onChangeSearch={setSearch}
      onChangeFilters={() => {}}
      rows={rows}
      availableFilters={[]}
    />
  );
};
