import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../../../api/apiClient';
import { CodeInput } from '../../../../components/CodeInput';
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/back-arrow.svg';
import styles from './styles.module.css';

export const EmailVerifyStep = () => {
  const [code, setCode] = useState('');
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();

  const email = localStorage.getItem('auth.email.address');

  const onVerify = (code: string) => {
    setAuthError('');
    apiClient.post('/users-permissions/auth/signUpEmailVerify', { email, token: code })
      .then((res) => {
        localStorage.setItem('hyve.access_token', res.data.jwt);
        const jwt = localStorage.getItem('hyve.access_token');
        const firstName = localStorage.getItem('auth.name.firstName');
        const lastName = localStorage.getItem('auth.name.lastName');
        const photoFileId = localStorage.getItem('auth.name.photoFileId');
        apiClient.post('/users-permissions/auth/signUp', { jwt, firstName, lastName, img: photoFileId });
        localStorage.removeItem('auth.name.firstName');
        localStorage.removeItem('auth.name.lastName');
        localStorage.removeItem('auth.photo.photoFileId');
        localStorage.removeItem('auth.photo.photoFileUrl');
        localStorage.removeItem('auth.email.address');
        localStorage.removeItem('auth.phone.number');
        navigate('/sign-up/success');
      })
      .catch(err => {
        setAuthError(err.response?.data?.error?.message || "Email code verification failed");
      });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onVerify(code);
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
    setAuthError('');
    if (value.length === 6) {
      onVerify(value);
    }
  };

  const goBack = () => {
    navigate('/sign-up/email');
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h2>Sign Up</h2>
      <p>We sent a confirmation code to {email}. Please check it.</p>
      <CodeInput onChange={handleCodeChange} autoFocus />
      <p className={styles.errorMessage}>{authError}</p>
      <div className={styles.controls}>
        <button type="button" className={styles.backBtn} onClick={goBack}>
          <BackArrowIcon />
        </button>
        <button className={styles.submitBtn} type="submit">
          Verify
        </button>
      </div>
    </form>
  );
};
