import React from 'react';
import { ReactComponent as UploadFileIcon } from '../../assets/icons/upload.svg';
import styles from './styles.module.css';

interface IEntityFileProps {
  name?: string;
}

export const EntityFile = ({ name }: IEntityFileProps) => {
  return (
    <div className={styles.dropzone}>
      <UploadFileIcon className={styles.uploadIcon} />
      <div className={`${styles.label} ${name ? styles.fileName : ''}`}>
        {name ?? "Drag & Drop to Upload or Browse File"}
      </div>
    </div>
  );
};
