import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as UploadFileIcon } from '../../assets/icons/upload.svg';
import styles from './styles.module.css';

interface IDropzoneProps {
  value?: File;
  onChange: (file?: File) => void;
  accept?: string;
}

export const Dropzone = ({ value, onChange, accept = 'image/*' }: IDropzoneProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setIsHovered(false);
    onChange(file);
  };

  return (
    <div
      className={`${styles.dropzone} ${isHovered ? styles.dropzoneHovered : ''}`}
      onDragOver={() => setIsHovered(true)}
      onDragLeave={() => setIsHovered(false)}
    >
      <UploadFileIcon className={styles.uploadIcon} />
      <div className={`${styles.label} ${value ? styles.fileName : ''}`}>
        {value ? value.name : "Drag & Drop to Upload or Browse File"}
      </div>
      <input
        className={styles.fileInput}
        type='file'
        accept={accept}
        onChange={onChangeFile}
      />
    </div>
  );
};
