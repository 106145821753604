import { gql } from '@apollo/client';

export const PartsListQuery = gql`
    query GetParts($page: Int, $search: String) {
        partModels(pagination: { page: $page, pageSize: 50 }, filters: { and: [{ name: { contains: $search } }, { name: { ne: "" } } ]}) {
            meta {
                pagination {
                    page
                    pageCount
                    total
                }
            }
            data {
                id
                attributes {
                    name
                    description
                    partNumber
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    known_part_model {
                        data {
                            attributes {
                                category
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DeletePartQuery = gql`
    mutation DeletePart($id: ID!) {
        deletePartModel(id: $id) {
            data {
                id
            }
        }
    }
`;
