import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { apiClient } from '../../../api/apiClient';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/Modal';
import { Dropzone } from '../../../components/Dropzone';
import { TextArea } from '../../../components/TextArea';
import { ToastContent } from '../../../components/ToastContent';
import { ReactComponent as ColumnsIcon } from '../../../assets/icons/columns.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { CreatePartQuery, UpdatePartQuery } from './query';
import styles from './styles.module.css';

export const CreatePartModal = ({ editModalData, onClose }: any) => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>(editModalData.name || '');
  const [number, setNumber] = useState<string>(editModalData.number || '');
  const [description, setDescription] = useState<string>(editModalData.description || '');
  const [photo, setPhoto] = useState<File>();

  const isEdit = editModalData?.type === 'edit';
  const actionName = isEdit ? 'Update' : 'Create';
  const actionDoneLabel = isEdit ? 'updated' : 'created';

  const [createPart] = useMutation(CreatePartQuery);
  const [updatePart] = useMutation(UpdatePartQuery);

  const onSubmit = async () => {
    let photoId;
    if (photo) {
      const form = new FormData();
      form.append('files', photo);
      const res = await apiClient.post('/upload', form);
      photoId = res.data?.[0]?.id;
    }

    const method = isEdit ? updatePart : createPart;
    const partRes = await method({
      variables: {
        id: isEdit ? editModalData.id : undefined,
        name,
        description,
        partNumber: number,
        images: photoId ? [photoId] : undefined,
      },
    });
    const newPartId = isEdit ? partRes?.data?.updatePartModel?.data?.id : partRes?.data?.createAssetModel?.data?.id;
    toast(<ToastContent title={`Part ${actionDoneLabel}!`} message={`Your new part is successfully ${actionDoneLabel}`} />);
    navigate(`/part/${newPartId}`);
    onClose();
  };

  if (!!editModalData) {
    return (
      <Modal onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.title}>
            <h1>{actionName} Part</h1>
          </div>
          <div className={styles.content}>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ColumnsIcon />
                Main Information
              </div>
              <div className={styles.fieldsColumn}>
                <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                <Input value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Number" />
                <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"
                          className={styles.descriptionField} />
              </div>
            </div>
            <div className={styles.dropzoneWrapper}>
              <div className={styles.category}>
                <div className={styles.subCategory}>
                  <ImageIcon/>
                  Images
                </div>
                <Dropzone value={photo} onChange={setPhoto} />
              </div>
            </div>
            <div className={styles.bottomActions}>
              <button className={styles.cancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button
                className={styles.createBtn} onClick={onSubmit}
                disabled={!name || !number}
              >
                {actionName}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
