import { gql } from '@apollo/client';

export const ProductModelsListQuery = gql`
    query GetProducts($page: Int, $search: String) {
        products(pagination: { page: $page, pageSize: 50 }, filters: { and: [{ name: { contains: $search } }, { name: { ne: "" } } ]}) {
            meta {
                pagination {
                    page
                    pageCount
                    total
                }
            }
            data {
                id
                attributes {
                    productID
                    name
                    description
                    assets {
                        data {
                          id
                        }
                    }
                    images {
                        data {
                            id
                            attributes {
                                name
                                url
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                business_info {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    product_category {
                        data {
                            id
                            attributes {
                                value
                                product_classification {
                                    data {
                                        id
                                        attributes {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                    }
                    resources {
                        data {
                            id
                            attributes {
                                name
                                type
                                url
                            }
                        }
                    }
                    specification {
                        data {
                            attributes {
                                height
                                depth
                                width
                                weight
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DeleteProductModelQuery = gql`
    mutation DeleteProductModel($id: ID!) {
        deleteProduct(id: $id) {
            data {
                id
            }
        }
    }
`;
