import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as PartIcon } from '../../assets/icons/part.svg';
import styles from './styles.module.css';
import { PartDetailsQuery } from './query';
import { Tabs } from '../../components/Tabs';
import { Loader } from '../../components/Loader';
import { PartNestedItemsTable } from './PartNestedItemsTable';

export const PartDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>('products');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { data, loading } = useQuery(PartDetailsQuery, {
    variables: { id },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const partModel = data?.partModel?.data;

  const partDetails = useMemo(
    () => ({
      name: partModel?.attributes.name,
      description: partModel?.attributes.description,
      spec: partModel?.attributes.specification?.data?.attributes,
      image: partModel?.attributes.images?.data?.[0]?.attributes.url,
      partNumber: partModel?.attributes.partNumber || '—',
      type: partModel?.attributes.known_part_model?.data?.attributes.type || '—',
      category: partModel?.attributes.known_part_model?.data?.attributes.category || '—',
      products: partModel?.attributes.products?.data || [],
      components: partModel?.attributes.components?.data || [],
    }),
    [partModel],
  );

  const tabs = useMemo(() => {
    return [{
      key: 'products',
      name: `Products (${partDetails?.products?.length})`,
    }, {
      key: 'components',
      name: `Components (${partDetails?.components?.length})`,
    }];
  }, [partDetails]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <button type="button" className={styles.backBtn} onClick={() => navigate('/parts')}>
              <BackArrowIcon />
            </button>
            <h1>Part</h1>
          </div>
          <div className={styles.detailsCard}>
            {loading ? (<Loader isCentered />) : (
              <>
                {partDetails.image ? <img className={styles.detailsImg} src={partDetails.image} alt="" /> : <PartIcon className={styles.detailsImg} />}
                <div style={{ flex: 1 }}>
                  <div className={styles.detailsInfoHeader}>
                    <span className={styles.name}>{partDetails.name}</span>
                    <p className={styles.dimensions}>
                      H: {partDetails.spec?.height || '—'}
                      {' '}L: {partDetails.spec?.depth || '—'}
                      {' '}W: {partDetails.spec?.width || '—'}
                      {' '}W: {partDetails.spec?.weight || '—'}
                    </p>
                  </div>
                  <p className={styles.description}>
                    {partDetails.description ? partDetails.description.slice(0, isExpanded ? undefined : 200) : <em>No description</em>}
                    {partDetails.description?.length > 200 ? <span className={styles.expandToggle} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Hide details' : 'Show more'}</span> : null}
                  </p>
                  <div className={styles.detailsInfoBottom}>
                    <div className={styles.detailsParam}>
                      <p>Number</p>
                      <span>{partDetails.partNumber || '—'}</span>
                    </div>
                    <div className={styles.detailsParam}>
                      <p>Type</p>
                      <span>{partDetails.type || '—'}</span>
                    </div>
                    <div className={styles.detailsParam}>
                      <p>Category</p>
                      <span>{partDetails.category || '—'}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <Tabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            <PartNestedItemsTable partData={partModel?.attributes} currentTab={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
