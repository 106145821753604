import { gql } from '@apollo/client';

export const ProductModelsQuery = gql`
    query GetProductModels($page: Int = 1, $manufacturer: ID) {
        products(pagination: { page: $page, pageSize: 10000 }, filters: { manufacturer: { id: { eq: $manufacturer } }, name: { ne: "" } }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;
