import { gql } from '@apollo/client';

export const CreateProductModelQuery = gql`
    mutation CreateProductModel($name: String, $description: String, $manufacturer: ID, $product_category: ID, $productID: String, $specification: ID, $images: [ID], $resources: [ID]) {
        createProduct(data: { name: $name, description: $description, manufacturer: $manufacturer, product_category: $product_category, productID: $productID, specification: $specification, images: $images, resources: $resources }) {
            data {
                id
            }
        }
    }
`;

export const UpdateProductModelQuery = gql`
    mutation UpdateProductModel($id: ID!, $name: String, $description: String, $manufacturer: ID, $product_category: ID, $specification: ID, $images: [ID], $resources: [ID]) {
        updateProduct(id: $id, data: { name: $name, description: $description, manufacturer: $manufacturer, product_category: $product_category, specification: $specification, images: $images, resources: $resources }) {
            data {
                id
            }
        }
    }
`;

export const CreateSpecificationQuery = gql`
    mutation CreateSpecification($height: Float, $depth: Float, $width: Float, $weight: Float) {
        createSpecification(data: { height: $height, depth: $depth, width: $width, weight: $weight }) {
            data {
                id
            }
        }
    }
`;

export const CreateProductResourceQuery = gql`
    mutation CreateProductResource($name: String, $type: ENUM_PRODUCTRESOURCE_TYPE, $url: String) {
        createProductResource(data: { name: $name, type: $type, url: $url }) {
            data {
                id
            }
        }
    }
`;
