import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from "./api/apolloClient";
import { AuthProvider } from "./contexts/AuthContext";
import { AppRouter } from "./routers/AppRouter";

export const App = () => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <AppRouter />
      <ToastContainer className='global-toast-wrapper' toastClassName='global-toast-container' hideProgressBar />
    </AuthProvider>
  </ApolloProvider>
);
