import { gql } from '@apollo/client';

export const PartModelsListQuery = gql`
    query GetPartModelsList {
        partModels(pagination: { pageSize: 1000 }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;
