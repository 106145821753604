import { gql } from '@apollo/client';

export const CreatePartQuery = gql`
    mutation CreatePart($name: String, $description: String, $partNumber: Long, $images: [ID]) {
        createPartModel(data: { name: $name, description: $description, partNumber: $partNumber, images: $images }) {
            data {
                id
            }
        }
    }
`;

export const UpdatePartQuery = gql`
    mutation UpdatePart($id: ID!, $name: String, $description: String, $partNumber: Long, $images: [ID]) {
        updatePartModel(id: $id, data: { name: $name, description: $description, partNumber: $partNumber, images: $images }) {
            data {
                id
            }
        }
    }
`;
