import { gql } from '@apollo/client';

export const BuildsQuery = gql`
    query GetBuilds {
        builds(pagination: { pageSize: 1000 }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;
