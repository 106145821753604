import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import styles from './styles.module.css';

export const ToastContent = ({ title = '', message = '' }) => {
  return (
    <div className={styles.wrapper}>
      <CheckIcon className={styles.icon} />
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};
