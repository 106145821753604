import { gql } from '@apollo/client';

export const CreateComponentQuery = gql`
    mutation CreateComponent($name: String, $description: String, $products: [ID], $partModels: [ID]) {
        createComponent(data: { name: $name, description: $description, products: $products, part_models: $partModels }) {
            data {
                id
            }
        }
    }
`;

export const UpdateComponentQuery = gql`
    mutation UpdateComponent($id: ID!, $name: String, $description: String, $products: [ID], $partModels: [ID]) {
        updateComponent(id: $id, data: { name: $name, description: $description, products: $products, part_models: $partModels }) {
            data {
                id
            }
        }
    }
`;
