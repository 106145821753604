import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import styles from './styles.module.css';

export const Modal = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => (
  <div className={styles.backdrop}>
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.content}>
        {children}
      </div>
      <CloseIcon className={styles.closeBtn} onClick={onClose} />
    </div>
  </div>
);
