import { ChangeEvent } from 'react';
import styles from './styles.module.css';

interface ITextAreaProps {
  label?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
}

export const TextArea = ({ label, value, onChange, placeholder = '', className = '' }: ITextAreaProps) => {
  const showSmallLabel = !label && placeholder;

  return (
    <div className={`${styles.textAreaWrapper} ${className}`}>
      {label && <p className={styles.label}>{label}</p>}
      <textarea className={styles.textArea} value={value} onChange={onChange} placeholder={placeholder} />
      {showSmallLabel && <p className={styles.smallLabel}>{showSmallLabel}</p>}
    </div>
  );
};
