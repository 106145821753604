import styles from './styles.module.css';

export const Loader = ({ isCentered = false, small = false }: { isCentered?: boolean; small?: boolean }) => {
  return (
    <div className={`${styles.ellipsisContainer} ${isCentered ? styles.ellipsisCentered : ''} ${small ? styles.ellipsisSmall : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
