import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { CreateAssetModal } from './CreateAssetModal';
import { AssetsListQuery, DeleteAssetQuery } from './query';
import Table from '../../components/Table/Table';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { PlusFloatingButton } from '../../components/PlusFloatingButton';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import styles from './styles.module.css';

export const AssetsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [editModalData, setEditModalData] = useState<Record<string, string>>();
  const { data, loading, refetch } = useQuery(AssetsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const [deleteAsset] = useMutation(DeleteAssetQuery);

  /* const availableFilters = [
    { key: 'category', label: 'Category', values: [
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Walk-in Freezer', label: 'Walk-in Freezer' },
        { key: 'Undercounter Refrigerator', label: 'Undercounter Refrigerator' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ]; */

  const rows = useMemo(
    () =>
      (data?.assets?.data || []).map((asset: any) => ({
        id: asset.id,
        name: asset.attributes.name,
        modelNumber: asset.attributes.product?.data?.attributes?.productID,
        modelName: asset.attributes.product?.data?.attributes?.name,
        productModelId: asset.attributes.product?.data?.id,
        manufacturerId: asset.attributes.product?.data?.attributes?.manufacturer?.data?.id,
        image: asset.attributes.images?.data?.[0]?.attributes?.url,
        serialNumber: asset.attributes.serialNumber,
        build: asset.attributes.product?.data?.attributes?.build?.data?.[0]?.attributes?.name || '–',
        buildId: asset.attributes.product?.data?.attributes?.build?.data?.[0]?.id,
        isInstallationRequired: asset.attributes.IsInstallationRequired || false,
        installationDate: asset.attributes.locationInstallationDate || '',
        kitchenArea: asset.attributes.kitchenArea || '',
        manufacturingDate: asset.attributes.manufacturingDate || '–',
      })),
    [data]
  );

  const onEdit = (params: any) => {
    const entityData = rows?.find((row: any) => row.id === params.data.id);
    setEditModalData({ type: 'edit', ...(entityData || {}) });
  };

  const onDelete = async (params: any) => {
    await deleteAsset({
      variables: { id: params.data.id },
    });
    refetch?.();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => {
              if (e.colDef?.field === 'actions') return;
              navigate(`/asset/${e.data.id}`);
            }}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Assets</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Asset Name',
                flex: 1,
                minWidth: 360,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
                pinned: 'left'
              },
              {
                field: 'modelNumber',
                headerName: 'Model #',
                width: 300,
                filterName: 'modelNumber',
              },
              {
                field: 'modelName',
                headerName: 'Model Name',
                flex: 1,
                minWidth: 200,
                filterName: 'modelName',
              },
              {
                field: 'build',
                headerName: 'Build',
                width: 200,
                filterName: 'build',
              },
              {
                field: 'serialNumber',
                headerName: 'Serial Number',
                width: 300,
                filterName: 'serialNumber',
              },
              {
                field: 'manufacturingDate',
                headerName: 'Date',
                width: 300,
                filterName: 'manufacturingDate',
              },
              {
                field: 'actions',
                headerName: '',
                width: 120,
                filterName: 'actions',
                cellRenderer: (params: any) => (
                  <div className={styles.actionsField}>
                    <EditIcon onClick={() => onEdit(params)} />
                    <CloseIcon onClick={() => onDelete(params)} />
                  </div>
                ),
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={[]}
            hideFilters
            paginationData={data?.assets?.meta?.pagination}
            onChangePage={setPage as any}
          />
          <PlusFloatingButton onClick={() => setEditModalData({ type: 'new' })} />
          {!!editModalData && <CreateAssetModal editModalData={editModalData} onClose={() => setEditModalData(undefined)} />}
        </div>
      </div>
    </div>
  );
};
