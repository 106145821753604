import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/chevron-left.svg';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import ManageColumnsController from './ManageColumnsController';
import './index.css';
import { Loader } from "../Loader";
import { Modal } from "../Modal";
import { Select } from "../Select";
import { useMediaQuery } from "../../hooks/useMediaQuery";
const Table = ({
  isLoading,
  columns,
  rows,
  onChangeSearch,
  onChangeFilters,
  search,
  filters,
  header,
  availableFilters,
  hideFilters = false,
  onClick = (e) => {},
  tableStyles = {},
  paginationData = null,
  onChangePage = () => {},
}) => {
  const gridRef = useRef();
  const [openFilters, setOpenFilters] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 1440px)');

  useEffect(() => {
    gridRef.current?.api?.resetRowHeights();
  }, [isSmallScreen]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    []
  );

  useEffect(() => {
    if (isLoading) {
      gridRef.current.api?.showLoadingOverlay();
    } else if (rows?.length) {
      gridRef.current.api?.hideOverlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, paginationData?.page]);

  const cellClickedListener = useCallback((event) => {
    onClick && onClick(event);
  }, [onClick]);

  // const filterableCols = useMemo(() => columns.filter((col) => col.filters), [columns]);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const pages = useMemo(() => {
    if (!paginationData?.pageCount) {
      return [0];
    }
    const pagesArr = Array.from({ length: paginationData?.pageCount }, (_, i) => i + 1);
    if (pagesArr.length > 10) {
      return [...pagesArr.slice(0, 5), '...', ...pagesArr.slice(-5)];
    }
    return pagesArr;
  }, [paginationData?.pageCount]);

  return (
    <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginTop: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          {header}
          {hideFilters ? null : <button className="filtersBtn" onClick={() => setOpenFilters(true)}>Filters</button>}
        </div>
        <div style={{ display: 'flex'}}>
          <input
            className="search"
            value={search}
            onChange={(e) => {
              onChangeSearch(e.target.value);
              if (paginationData?.page > 1) {
                onChangePage(1);
              }
            }}
            placeholder="Search"
          />
          <button className='export-btn' onClick={onBtnExport}>
            <DownloadIcon />
            Download CSV
          </button>
        </div>
      </div>
      <div
        style={{ flex: 1, marginTop: '18px', ...tableStyles }}
        className="ag-gr-wrapper ag-theme-alpine"
      >
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          onGridReady={() => isLoading && gridRef.current.api?.showLoadingOverlay()}
          loadingOverlayComponent={() => <Loader />}
          headerHeight={isSmallScreen ? 50 : 65}
          rowHeight={isSmallScreen ? 60 : 80}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          animateRows
          onCellClicked={cellClickedListener}
          scrollbarWidth={0}
        />
      </div>
      {paginationData ? <div className='table-pagination'>
        <LeftArrowIcon onClick={() => paginationData?.page > 1 && onChangePage(paginationData?.page - 1)} />
        {pages.map((page) => (
          <span key={page} onClick={() => onChangePage(page)} className={`${typeof page === 'string' ? 'disabled' : ''}${page === paginationData?.page ? " active" : ''}`}>{page}</span>
        ))}
        <LeftArrowIcon style={{ transform: 'scaleX(-1)' }} onClick={() => paginationData?.page < paginationData?.pageCount && onChangePage(paginationData?.page + 1)} />
      </div> : null}
      {openFilters && (
        <Modal onClose={() => setOpenFilters(false)}>
          <div className="filters">
            <h1>Filters</h1>
            <div>
              {availableFilters.map((filter) => (
                <Select
                  label={filter.label}
                  value={filters[filter.key]}
                  placeholder={`Select ${filter.label.toLowerCase()}`}
                  options={filter.values.map(val => ({ key: val.key, label: val.label }))}
                  onChange={(newValue) => {
                    onChangeFilters(prevFilters => ({ ...prevFilters, [filter.key]: newValue }));
                    if (paginationData?.page > 1) {
                      onChangePage(1);
                    }
                  }}
                />
              ))}
            </div>
            <button className="applyBtn" onClick={() => setOpenFilters(false)}>Apply</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Table;
