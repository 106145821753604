import { gql } from '@apollo/client';

export const CreateAssetQuery = gql`
    mutation CreateAsset($name: String, $product: ID, $serialNumber: String, $manufacturingDate: Date, $images: [ID], $q_rcodes: [ID], $IsInstallationRequired: Boolean, $locationInstallationDate: Date, $kitchenArea: ENUM_ASSET_KITCHENAREA) {
        createAsset(data: { name: $name, product: $product, serialNumber: $serialNumber, manufacturingDate: $manufacturingDate, images: $images, q_rcodes: $q_rcodes, IsInstallationRequired: $IsInstallationRequired, locationInstallationDate: $locationInstallationDate, kitchenArea: $kitchenArea }) {
            data {
                id
            }
        }
    }
`;

export const UpdateAssetQuery = gql`
    mutation UpdateAsset($id: ID!, $name: String, $product: ID, $serialNumber: String, $manufacturingDate: Date, $images: [ID], $q_rcodes: [ID], $IsInstallationRequired: Boolean, $locationInstallationDate: Date, $kitchenArea: ENUM_ASSET_KITCHENAREA) {
        updateAsset(id: $id, data: { name: $name, product: $product, serialNumber: $serialNumber, manufacturingDate: $manufacturingDate, images: $images, q_rcodes: $q_rcodes, IsInstallationRequired: $IsInstallationRequired, locationInstallationDate: $locationInstallationDate, kitchenArea: $kitchenArea }) {
            data {
                id
            }
        }
    }
`;

export const CreateQrCodeQuery = gql`
    mutation CreateQRcode($qrID: String) {
        createQRcode(data: { qrID: $qrID }) {
            data {
                id
            }
        }
    }
`;
