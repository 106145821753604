import { useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import styles from './styles.module.css';
import { useHandleOutsideMouseDown } from "../../hooks/useHandleOutsideMouseDown";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

interface ISelectProps {
  label?: string;
  value: string;
  options: { key: string; label: string }[];
  onChange: (optionKey: string) => void;
  placeholder?: string;
  disabled?: boolean;
  fetchMore?: () => void;
}

export const Select = ({ label, value, options = [], onChange, placeholder = 'Select...', disabled = false, fetchMore }: ISelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedLabel = options.find(option => option.key === value)?.label;
  const showSmallLabel = !label && placeholder;
  useHandleOutsideMouseDown(selectRef, () => {
    setShowOptions(false);
    setSearchValue(null);
  });
  useInfiniteScroll(optionsListRef, { threshold: 1 }, fetchMore);

  return (
    <div className={styles.selectWrapper}>
      {label && <p className={styles.label}>{label}</p>}
      <div
        className={`${styles.select} ${disabled ? styles.selectDisabled : ''}`}
        onClick={() => {
          if (showOptions) {
            setSearchValue(null);
          }
          !disabled && setShowOptions(!showOptions);
        }}
        ref={selectRef}
      >
        <input
          className={styles.searchInput}
          value={searchValue ?? selectedLabel ?? ''}
          placeholder={placeholder}
          onChange={e => setSearchValue(e.target.value)}
        />
        <ArrowDownIcon className={showOptions ? styles.iconInverted : styles.icon} />
        {showOptions && (
          <div className={styles.optionsList}>
            {options.filter(option => option.label?.toLowerCase().includes(searchValue?.toLowerCase() || '')).map((option, index) => (
              <div
                key={option.key}
                className={styles.option}
                onClick={() => onChange(option.key)}
                ref={index === options.length - 3 ? optionsListRef : undefined}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {showSmallLabel && <p className={`${styles.smallLabel} ${selectedLabel ? styles.smallLabelActive : ''}`}>
        {showSmallLabel}
      </p>}
    </div>
  );
};
