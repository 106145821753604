import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as ComponentIcon } from '../../assets/icons/component.svg';
import styles from './styles.module.css';
import { ComponentDetailsQuery } from './query';
import { Tabs } from '../../components/Tabs';
import { Loader } from '../../components/Loader';
import { ComponentNestedItemsTable } from './ComponentNestedItemsTable';

export const ComponentDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>('products');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { data, loading } = useQuery(ComponentDetailsQuery, {
    variables: { id },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const component = data?.component?.data;

  const componentDetails = useMemo(
    () => ({
      name: component?.attributes.name,
      description: component?.attributes.description,
      status: component?.attributes.status || '—',
      image: component?.attributes.images?.data?.[0]?.attributes.url,
      type: '—',
      products: component?.attributes.products?.data || [],
      parts: component?.attributes.part_models?.data || [],
    }),
    [component],
  );

  const tabs = useMemo(() => {
    return [{
      key: 'products',
      name: `Product Models (${componentDetails?.products?.length})`,
    }, {
      key: 'parts',
      name: `Model Parts (${componentDetails?.parts?.length})`,
    }];
  }, [componentDetails]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <button type="button" className={styles.backBtn} onClick={() => navigate('/components')}>
              <BackArrowIcon />
            </button>
            <h1>Component</h1>
          </div>
          <div className={styles.detailsCard}>
            {loading ? (<Loader isCentered />) : (
              <>
                {componentDetails.image ? <img className={styles.detailsImg} src={componentDetails.image} alt="" /> : <ComponentIcon className={styles.detailsImg} />}
                <div style={{ flex: 1 }}>
                  <div className={styles.detailsInfoHeader}>
                    <span className={styles.name}>{componentDetails.name}</span>
                  </div>
                  <p className={styles.description}>
                    {componentDetails.description ? componentDetails.description.slice(0, isExpanded ? undefined : 200) : <em>No description</em>}
                    {componentDetails.description?.length > 200 ? <span className={styles.expandToggle} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Hide details' : 'Show more'}</span> : null}
                  </p>
                  <div className={styles.detailsInfoBottom}>
                    <div className={styles.detailsParam}>
                      <p>Type</p>
                      <span>{componentDetails.type || '—'}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <Tabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            <ComponentNestedItemsTable componentData={component?.attributes} currentTab={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
