import { gql } from '@apollo/client';

export const ProductClassificationsQuery = gql`
    query GetProductClassifications {
        productClassifications {
            data {
                id
                attributes {
                    type
                    value
                    product_categories(pagination: { page: 1, pageSize: 1000 }, sort: "value:asc") {
                        data {
                            id
                            attributes {
                                type
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`;
