import { useMemo, useState } from 'react';
import Table from "../../components/Table/Table";
import styles from "../product-models/styles.module.css";
import { ReactComponent as ProductModelIcon } from '../../assets/icons/menus/product-models.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';

export const ComponentNestedItemsTable = ({ componentData, currentTab }: any) => {
  const [search, setSearch] = useState('');
  const rows = useMemo(
    () => {
      if (currentTab === 'products') {
        return (componentData?.products?.data || []).map((product: any) => ({
          id: product?.id,
          name: product?.attributes.name,
          image: product?.attributes.images?.data?.[0]?.attributes.url,
          category: product?.data?.attributes.product_category?.data?.attributes.value || '—',
          classification: product?.data?.attributes.product_category?.data?.attributes.product_classification?.data?.attributes.value || '—',
          manufacturer: product?.attributes.manufacturer?.data?.attributes.business_info?.data?.attributes.name || '—',
          assetAmount: product?.attributes.assets?.data?.length ?? '—',
        })).filter((product: any) => Object.values(product).some((productParam: any) => productParam && typeof productParam === 'string' && productParam.toLowerCase().includes(search.toLowerCase())));
      }
      if (currentTab === 'parts') {
        return (componentData?.part_models?.data || []).map((component: any) => ({
          id: component?.id,
          name: component?.attributes?.name,
          image: component?.attributes?.images?.data?.[0]?.attributes?.url,
          status: 'Active',
        })).filter((component: any) => Object.values(component).some((componentParam: any) => componentParam && typeof componentParam === 'string' && componentParam.toLowerCase().includes(search.toLowerCase())));
      }
    },
    [componentData, currentTab, search]
  );

  const columns = useMemo(
    () => {
      if (currentTab === 'products') {
        return [
          {
            field: 'name',
            headerName: 'Product Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <ProductModelIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'category',
            headerName: 'Category',
            minWidth: 400,
            flex: 1,
            filterName: 'category',
          },
          {
            field: 'classification',
            headerName: 'Classification',
            minWidth: 200,
            flex: 1,
            filterName: 'classification',
          },
          {
            field: 'manufacturer',
            headerName: 'Manufacturer',
            minWidth: 360,
            flex: 1,
            filterName: 'manufacturer',
          },
          {
            field: 'assetAmount',
            headerName: 'Asset Amount',
            minWidth: 360,
            flex: 1,
            filterName: 'assetAmount',
          },
        ];
      }
      if (currentTab === 'parts') {
        return [
          {
            field: 'name',
            headerName: 'Component Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'id',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'id',
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 300,
            flex: 1,
            filterName: 'status',
          },
        ];
      }
    },
    [currentTab]
  );

  return (
    <Table
      isLoading={false}
      onClick={(e: any) => {}}
      columns={columns}
      filters={[]}
      search={search}
      header={undefined}
      hideFilters
      onChangeSearch={setSearch}
      onChangeFilters={() => {}}
      rows={rows}
      availableFilters={[]}
    />
  );
};
