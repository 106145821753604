import React from 'react';
import styles from './styles.module.css';

interface IButtonCircularProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  isDark?: boolean;
  width?: number;
  height?: number;
}

export const ButtonCircular = ({ children, isDisabled = false, isDark = false, width = 48, height = 48 }: IButtonCircularProps) => (
  <div
    className={`${styles.containerWithBackground}${isDark ? ` ${styles.darkVersion}` : ''}${isDisabled ? ` ${styles.disabled}` : ''}`}
    style={{ width, height }}
  >
    {children}
  </div>
);
