import { gql } from '@apollo/client';

export const PartDetailsQuery = gql`
    query GetPart($id: ID) {
        partModel(id: $id) {
            data {
                id
                attributes {
                    name
                    partNumber
                    description
                    components(pagination: { page: 1, pageSize: 100 }) {
                        data {
                            id
                            attributes {
                                name
                                status
                            }
                        }
                    }
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    known_part_model {
                        data {
                            id
                            attributes {
                                type
                            }
                        }
                    }
                    products(pagination: { page: 1, pageSize: 100 }) {
                        data {
                            id
                            attributes {
                                name
                                productID
                                description
                                isVerified
                                assets {
                                    data {
                                        id
                                    }
                                }
                                images {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                manufacturer {
                                    data {
                                        id
                                        attributes {
                                            business_info {
                                                data {
                                                    id
                                                    attributes {
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                product_category {
                                    data {
                                        attributes {
                                            value
                                            product_classification {
                                                data {
                                                    attributes {
                                                        value
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    specification {
                        data {
                            attributes {
                                height
                                depth
                                width
                                weight
                            }
                        }
                    }
                }
            }
        }
    }
`;
