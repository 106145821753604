import { gql } from '@apollo/client';

export const ManufacturersQuery = gql`
    query GetManufacturers {
        manufacturers(pagination: { pageSize: 1000 }, filters: { business_info: { name: { ne: "" } } }, sort: "business_info.name:asc") {
            data {
                id
                attributes {
                    manufacturerID
                    business_info {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
