import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useInfiniteScroll = (
  targetRef: MutableRefObject<HTMLElement | null>,
  options: IntersectionObserverInit,
  fetchMore?: () => void
) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchMore?.();
        }
      });
    },
    [fetchMore],
  );

  useEffect(() => {
    if (targetRef.current && fetchMore) {
      observer.current = new IntersectionObserver(handleIntersection, options);
      observer.current.observe(targetRef.current);
    }

    return () => {
      observer.current?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef, handleIntersection, options]);

  return observer;
};
