import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { apiClient } from '../../../api/apiClient';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/Modal';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { Dropzone } from '../../../components/Dropzone';
import { Loader } from "../../../components/Loader";
import { EntityFile } from '../../../components/EntityFile';
import { ToastContent } from '../../../components/ToastContent';
import { ProductClassificationsQuery } from '../../../api/common-queries/classifications';
import { ManufacturersQuery } from '../../../api/common-queries/manufacturers';
import { ReactComponent as ColumnsIcon } from '../../../assets/icons/columns.svg';
import { ReactComponent as PartsIcon } from '../../../assets/icons/part.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import {
  CreateProductModelQuery,
  CreateProductResourceQuery,
  CreateSpecificationQuery,
  UpdateProductModelQuery,
} from './query';
import styles from './styles.module.css';

export const CreatePMModal = ({ editModalData, onClose }: any) => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>(editModalData.name || '');
  const [description, setDescription] = useState<string>(editModalData.description || '');
  const [manufacturer, setManufacturer] = useState<string>(editModalData.manufacturerId || '');
  const [classification, setClassification] = useState<string>(editModalData.classificationId || '');
  const [category, setCategory] = useState<string>(editModalData.categoryId || '');
  const [height, setHeight] = useState<string>(editModalData.height || '');
  const [width, setWidth] = useState<string>(editModalData.width || '');
  const [length, setLength] = useState<string>(editModalData.depth || '');
  const [weight, setWeight] = useState<string>(editModalData.weight || '');
  const [photos, setPhotos] = useState<File[]>([]);
  const [resources, setResources] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data } = useQuery(ProductClassificationsQuery);
  const { data: manufacturersData } = useQuery(ManufacturersQuery);
  const [createProductModel] = useMutation(CreateProductModelQuery);
  const [updateProductModel] = useMutation(UpdateProductModelQuery);
  const [createSpecification] = useMutation(CreateSpecificationQuery);
  const [createProductResource] = useMutation(CreateProductResourceQuery);
  const manufacturers = manufacturersData?.manufacturers?.data || [];

  const previouslyAddedImages = editModalData?.images ?? [];
  const previouslyAddedResources = editModalData?.resources ?? [];

  const isEdit = editModalData?.type === 'edit';
  const actionName = isEdit ? 'Update' : 'Create';
  const actionDoneLabel = isEdit ? 'updated' : 'created';

  const classifications = useMemo(() => data?.productClassifications?.data || [], [data]);

  const categoriesOptions = useMemo(() => {
    const currentClassification = classifications.find((cl: any) => cl.id === classification);
    if (currentClassification) {
      return currentClassification.attributes.product_categories?.data?.map((cat: any) => ({ key: cat.id, label: cat.attributes.value }));
    }
    return [];
  }, [classifications, classification]);

  const onSubmit = async () => {
    setIsLoading(true);
    const previouslyAddedImagesIds = previouslyAddedImages.map((previouslyAddedImage: any) => previouslyAddedImage.id);
    let photoIds: string[] = [...previouslyAddedImagesIds];
    if (photos?.length) {
      await Promise.all(photos.map(async (photo) => {
        const form = new FormData();
        form.append('files', photo);
        const res = await apiClient.post('/upload', form);
        photoIds = [...photoIds, res.data?.[0]?.id];
      }));
    }

    let createdResources: any[] = [];
    if (resources?.length) {
      try {
        await Promise.all(resources.map(async (resource) => {
          const form = new FormData();
          form.append('files', resource);
          const res = await apiClient.post('/upload', form);
          createdResources = [...createdResources, res.data?.[0]];
        }));
      } catch (e) {
        console.error("Failed to upload resources:", e);
      }
    }

    let specificationRes;
    if (width || height || length || weight) {
      specificationRes = await createSpecification({
        variables: {
          width: parseFloat(width),
          height: parseFloat(height),
          depth: parseFloat(length),
          weight: parseFloat(weight),
        },
      });
    }

    const productResourceResponses = await Promise.all(createdResources.map(createdResource => {
      return createProductResource({
        variables: {
          name: createdResource.name,
          type: 'RESOURCE_DOCUMENT',
          url: createdResource.url,
        },
      });
    }))

    const previouslyAddedResourcesIds = previouslyAddedResources.map((previouslyAddedResource: any) => previouslyAddedResource.id);
    const newResourcesIds = productResourceResponses.map(productResourceResponse => productResourceResponse?.data?.createProductResource?.data?.id);
    const resourcesIds: any[] = [...previouslyAddedResourcesIds, ...newResourcesIds];
    const newSpecificationId = specificationRes?.data?.createSpecification?.data?.id;
    const method = isEdit ? updateProductModel : createProductModel;
    const productModelRes = await method({
      variables: {
        id: isEdit ? editModalData.id : undefined,
        name,
        description,
        manufacturer,
        product_category: category,
        productID: isEdit ? undefined : new Date().getTime().toString(),
        specification: newSpecificationId ?? null,
        images: photoIds?.length ? photoIds : undefined,
        resources: resourcesIds,
      },
    });

    const newProductModelId = isEdit ? productModelRes?.data?.updateProduct?.data?.id : productModelRes?.data?.createProduct?.data?.id;

    toast(<ToastContent title={`Product Model ${actionDoneLabel}!`} message={`Your new product model is successfully ${actionDoneLabel}`} />);
    navigate(`/product-model/${newProductModelId}`);
    setIsLoading(false);
    onClose();
  };

  if (!!editModalData) {
    return (
      <Modal onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.title}>
            <h1>{actionName} Product Model</h1>
          </div>
          <div className={styles.content}>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ColumnsIcon />
                Main Information
              </div>
              <div className={styles.fieldsRow}>
                <div className={styles.fieldsColumn}>
                  <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                  <Select
                    value={manufacturer}
                    placeholder="Manufacturer"
                    options={manufacturers.map((manufacturer: any) => ({
                      key: manufacturer.id,
                      label: manufacturer.attributes.business_info?.data?.attributes?.name || `[ID ${manufacturer.id}]`
                    }))}
                    onChange={(newValue) => {
                      setManufacturer(newValue);
                    }}
                  />
                  <Select
                    value={classification}
                    placeholder="Classification"
                    options={classifications.map((classification: any) => ({
                      key: classification.id,
                      label: classification.attributes.value
                    }))}
                    onChange={(newValue) => {
                      setClassification(newValue);
                    }}
                  />
                  <Select
                    value={category}
                    placeholder="Select Category"
                    options={categoriesOptions}
                    onChange={(newValue) => {
                      setCategory(newValue);
                    }}
                    disabled={!classification}
                  />
                </div>
                <div>
                  <TextArea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                    className={styles.descriptionField}
                  />
                </div>
              </div>
            </div>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <PartsIcon />
                Specifications
              </div>
              <div>
                <div className={styles.fieldsRow}>
                  <div className={styles.fieldsColumn}>
                    <Input type='number' value={height} onChange={(e) => setHeight(e.target.value)}
                           placeholder="Height (in)" />
                    <Input type='number' value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Width (in)" />
                  </div>
                  <div className={styles.fieldsColumn}>
                    <Input type='number' value={length} onChange={(e) => setLength(e.target.value)}
                           placeholder="Length (in)" />
                    <Input type='number' value={weight} onChange={(e) => setWeight(e.target.value)}
                           placeholder="Weight (lb)" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ImageIcon />
                Images
              </div>
              <div className={styles.resources}>
                {previouslyAddedImages.map((previouslyAddedImage: any) => (
                  <EntityFile key={previouslyAddedImage.id} name={previouslyAddedImage.attributes.name} />
                ))}
                {Array(photos.length + 1).fill(undefined).map((_, index) => (
                  <Dropzone
                    key={index}
                    value={photos[index]}
                    onChange={(newRes) => setPhotos(prevRes => [...prevRes, newRes as File])}
                  />
                ))}
              </div>
            </div>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <DocumentIcon />
                Resources
              </div>
              <div className={styles.resources}>
                {previouslyAddedResources.map((previouslyAddedResource: any) => (
                  <EntityFile key={previouslyAddedResource.id} name={previouslyAddedResource.attributes.name} />
                ))}
                {Array(resources.length + 1).fill(undefined).map((_, index) => (
                  <Dropzone
                    key={index}
                    value={resources[index]}
                    onChange={(newRes) => setResources(prevRes => [...prevRes, newRes as File])}
                    accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf'
                  />
                ))}
              </div>
            </div>
            <div className={styles.bottomActions}>
              <button className={styles.cancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button
                className={styles.createBtn} onClick={onSubmit}
                disabled={isLoading || !name || !manufacturer || !classification || !category}
              >
                {isLoading ? <Loader small /> : actionName}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
